import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, split } from "apollo-link";
import customFetch from "isomorphic-fetch";
import { defaults, typeDefs, resolvers } from "./store";

const cache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: (object: any) => object.__typename + object.id,
});

const authLink = new ApolloLink((op: any, forward: any) => {
  op.setContext(({ headers }: any) => {
    return {
      headers,
    };
  });
  return forward(op);
});

const httpLink = new HttpLink({
  uri: process.env.GATSBY_TEST
    ? process.env.GATSBY_API_TEST_URL
    : process.env.GATSBY_BRANCH === "prod"
    ? process.env.GATSBY_API_PROD_URL
    : process.env.GATSBY_API_MASTER_URL,
  credentials: "same-origin",
  fetch: customFetch,
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(e => {
      console.log(e.response, JSON.stringify(e.networkError));
      if (e.graphQLErrors)
        e.graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      if (e.networkError) console.log(`[Network error]: ${e.networkError}`);
    }),
    authLink,
    // link,
    httpLink,
  ]),
  cache,
  // typeDefs,
  resolvers,
});

cache.writeData({ data: defaults });
