import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./src/apollo/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "normalize.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfmdcgUAAAAAOdN9dXidT0moWiQw3Cu5w4Z0CNu">
        <ToastContainer />
        {element}
      </GoogleReCaptchaProvider>
    </ApolloProvider>
  );
};
